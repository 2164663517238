import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(""); // State for success/error messages
  const [isError, setIsError] = useState(false); // State to track error or success

  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!formData.phone) {
      newErrors.phone = "Mobile No. is required";
    }
    if (!formData.message) {
      newErrors.message = "Message cannot be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return; // Stop submission if validation fails
    }

    try {
      const response = await axios.post('/send-contact', formData);

      if (response.data.success) {
        setMessage("Your message has been sent successfully!");
        setIsError(false); // Set success state
      } else {
        setMessage("Failed to send your message. Please try again.");
        setIsError(true); // Set error state
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setMessage("Error sending email. Please try again.");
      setIsError(true); // Set error state
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us | Codezion Softwares</title>
        <meta name="title" content="Contact Us | Codezion Softwares" />
        <meta
          name="description"
          content="Reach out to us to discuss your project requirements and receive a free quote or project estimation. We're ready to respond promptly and assist you with your needs!"
        />
        <meta name="keywords" content="contact us, contact codezion" />
        <meta property="og:title" content="Contact Us | Codezion Softwares" />
        <meta property="og:site_name" content="codezion" />
        <meta property="og:url" content="https://www.codezion.com/contactus" />
        <meta
          property="og:description"
          content="Reach out to us to discuss your project requirements and receive a free quote or project estimation. We're ready to respond promptly and assist you with your needs!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.codezion.com/assets/images/logo 1.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Contact Us | Codezion Softwares" />
        <meta name="twitter:site" content="@codezion" />
        <meta
          name="twitter:description"
          content="Reach out to us to discuss your project requirements and receive a free quote or project estimation. We're ready to respond promptly and assist you with your needs!"
        />
        <meta name="twitter:image" content="https://www.codezion.com/" />
        <meta name="twitter:image:alt" content="codezionsoftware" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout />
      <section className="section-padding subheader-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="subheader">
                <h1>Contact Us</h1>
                <span>
                  <Link to="/">
                    Home <i className="fa fa-chevron-right"></i>
                  </Link>{" "}
                  Contact Us
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --section start---- */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-title">
                <p>Get In Touch</p>
                <h2>
                  Get answers to your queries by filling out this form with all
                  the requested details.
                </h2>
                <div className="media">
                  <div className="media-icon-part align-self-center" style={{ width: "115px" }}>
                    <img src="assets/images/location.gif" alt="Location" />
                  </div>
                  <div className="media-body">
                    <h4 className="feature-box-title">Location</h4>
                    <div className="fbox-content">
                      C-188, B-2, Ground Floor, Ayesha Majestic Near LBS College
                      Sunder Marg Tilak Nagar, Raja Park, Jaipur, Rajasthan 302004
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-icon-part align-self-center">
                    <img src="assets/images/phone.gif" alt="Phone" />
                  </div>
                  <div className="media-body">
                    <h4 className="feature-box-title">Any Questions?</h4>
                    <div className="fbox-content">8386893777, 9772564292</div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-icon-part align-self-center">
                    <img src="assets/images/briefcase.gif" alt="Email" />
                  </div>
                  <div className="media-body">
                    <h4 className="feature-box-title">Write Email</h4>
                    <div className="fbox-content">info@codezion.com</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {message && (
                <p
                  style={{
                    backgroundColor: isError ? "red" : "green",
                    color: "white",
                    textAlign: "center",
                    padding: "6px",
                  }}
                >
                  {message}
                </p>
              )}
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <div className="text-danger">{errors.name}</div>}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && <div className="text-danger">{errors.message}</div>}
                  </div>
                  <button type="submit" className="btn-thm-main">
                    SEND NOW
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.445659919379!2d75.82569961504374!3d26.890855183133315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4727da79d8d%3A0xbac10ae614f58b95!2sCodezion%20Softwares!5e0!3m2!1sen!2sin!4v1672677495694!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          title="location"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
